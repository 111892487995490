import axios from "axios";

export default class DeviceService {
  urlBase = "";
  token = "";

  constructor() {
    //this.urlBase =  'http://localhost:8000/'
    this.urlBase =  process.env.REACT_APP_ACCESS_CORE_URL;
  }

  //curl -X GET http://localhost:8000/api/doors?
  //response [{"id":1,"device_id":1,"relay_number":1,"name":"Torre Nico","enabled":1,"created_at":"2024-10-07T17:39:36.000000Z","updated_at":"2024-10-07T17:39:36.000000Z"}]
  getDoors = (params) => {
    return axios.get(this.urlBase + "/api/devices?" + params);
  }

  getDeviceDoors = (device_id) =>{
    return axios.get(this.urlBase + "/api/getDeviceDoors?" + "device_id=" + device_id )
  }

  getDevicePortMap = (device_id) =>{
    return axios.get(this.urlBase + "/api/getDevicePortMap?" + "device_id=" + device_id )
  }
}