import DeviceService from "../services/DeviceService";
import { useState, useEffect } from "react";


const useDevice = () => { 
    const deviceService = new DeviceService();
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    const getDevices = async () => {
      try {
        const response = await deviceService.getDoors();
        setDevices(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error);
       setLoading(false);
      }
    };
    
    useEffect(() => {
      getDevices();
    }, []);
    return { devices, loading, error };

}

export default useDevice;