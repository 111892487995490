import { useState, useEffect } from 'react';
import DoorService from '../services/DoorService';


const useUsersDoors = () => {
    const doorService = new DoorService();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const getUsers = async () => {
        try {
            const response = await doorService.getDoors();
            setUsers(response.data);
            setLoading(false);
        }catch (error) {
            console.log(error);
            setError(error);
           setLoading(false);
        }
    }

    
    useEffect(() => {
        getUsers();
    },[]);
    return { users, loading, error }
}

export default useUsersDoors;