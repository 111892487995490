import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  Divider,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FaceIcon from '@mui/icons-material/Face';
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { SensorDoor } from "@mui/icons-material";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";

export default function Sidebar() {
  const location = useLocation();
  const [activePage, setActivePage] = useState(location.pathname);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleClick = (page) => {
    setActivePage(page);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    if (isCollapsed) {
      document.getElementById("sidebar").style.position = "fixed";
    } else {
      document.getElementById("sidebar").style.position = "relative";
    }
  };

  return (
    <Box id="sidebar"
      sx={{
        zIndex:100
      }}
    >
      <Drawer
        variant="permanent"
        sx={{
          width: isCollapsed ? 60 : 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: isCollapsed ? 60 : 240,
            transition: "width 0.3s",
          
          },
        }}
      >
        <List>
          <ListItem button onClick={toggleCollapse}>
            <ListItemIcon>
              {isCollapsed ? <MenuIcon /> : <CloseIcon />}
            </ListItemIcon>
            {!isCollapsed && <ListItemText primary="Cerrar" />}
          </ListItem>
          <Divider />
          <ListItem
            button
            component={Link}
            to="/"
            onClick={() => handleClick("/")}
          >
            <ListItemIcon>
              <SensorDoor />
            </ListItemIcon>
            {!isCollapsed && <ListItemText primary="Tus Puertas" />}
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/devices"
            onClick={() => handleClick("/devices")}
          >
            <ListItemIcon>
              <DeviceHubIcon />
            </ListItemIcon>
            {!isCollapsed && <ListItemText primary="Tus Dispositivos" />}
          </ListItem>
          <ListItem
          button
          component={Link}
          to="/faceRecognitionRegister"
          onClick={() => handleClick("/faceRecognitionRegister")}>
            <ListItemIcon>
              <FaceIcon/>
            </ListItemIcon>
            {!isCollapsed && <ListItemText primary="Reconocimiento Facial" />}
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
