import axios from "../axiosConfig";

export default class DoorService {
  urlBase = "";
  token = "";

  constructor() {
    //this.urlBase =  'http://localhost:8000/';
    this.urlBase =  process.env.REACT_APP_ACCESS_CORE_URL;
  }

  //curl -X POST {urlBase}/api/generate-code
  async generateCode() {
    try {
      const response = await axios.post(this.urlBase + "/api/generate-code");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  //curl -X POST {urlBase}/api/authorize-door -d {code, door_id}
  async authorizeDoor(code, door_id) {
    try {
      const response = await axios.post(this.urlBase + "/api/authorize-door", { code, door_id });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

}