import { useState, useEffect } from "react";
import { Box, Typography, IconButton, Stack, Button, Menu, MenuItem, Modal as MuiModal, TextField, Alert, Modal } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useDoors from "../hooks/DoorsHook";
import AuthorizeDoor from "../services/DoorShareService";
import SendIcon from '@mui/icons-material/Send';
import { Link, useLocation } from "react-router-dom";



const GenerateCode = ({ open, handleClose, guestCode }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(guestCode).then(() => {
      alert('Código copiado al portapapeles');
    }).catch(err => {
      console.error('Error al copiar el código: ', err);
    });
  };

  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '350px', backgroundColor: 'white', padding: 6, boxShadow: 24, zIndex: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 2 }}>
          <Typography id="modal-title" variant="h6" sx={{ fontWeight: 'bold' }}>
            {guestCode === "" ? "Generando..." : "Tú código de invitado es:"}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Stack
          sx={{ width: '100%', alignItems: 'center' }}
          spacing={2}
          noValidate
          autoComplete="off"
        >
          <Typography variant="body1" className="text-center text-lg font-mono">
            {guestCode}
          </Typography>
          {guestCode !== "" && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<ContentCopyIcon />}
              onClick={handleCopy}
            >
              Copiar código
            </Button>
          )}
        </Stack>
        {guestCode !== "" && (
          <Typography variant="subtitle2" className="text-center" sx={{ marginTop: 2 }}>
            Compártelo con el administrador de la puerta.
          </Typography>
        )}
      </Box>
    </MuiModal>
  );
};

const ITEM_HEIGHT = 48;

const ThreePointsMenu = ({ id , type })=> {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showShare, setShowShare] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const handleCloseShare = () => {
    setShowShare(false);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {type == true  && 
        <MenuItem
        button
        component={Link}
        to={"/users/access"}
        >
          Compartir
        </MenuItem>
    }
        <MenuItem onClick={handleClose}>
          Añadir a favoritos
        </MenuItem>
      </Menu>
    </div>
  );
}

const DoorsComponent = () => {
  const {doors, error, loadingDoors, createAccessRequest, generateCode, code} = useDoors();
  const [processingDoor, setProcessingDoor] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleAddClick = () => {
    generateCode();
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };


  return (
    <div className="flex flex-col">
      <main className="flex-1 overflow-y-auto z">
        <Box className="flex items-center justify-between">
          <Typography variant="h5" className="font-bold mb-4">
            Tus puertas
          </Typography>
          <IconButton onClick={handleAddClick}>
            <AddIcon />
          </IconButton>
        </Box>
        <Box>
          {loadingDoors && (
            <Typography className="text-center">Cargando...</Typography>
          )}
          {!loadingDoors && doors.length === 0 && (
            <Typography className="text-center">
              No hay puertas disponibles
            </Typography>
          )}
          {doors.map((row, index) => (
            <Box
              key={index}
              className={`flex items-center mb-3 rounded-lg shadow ${
                processingDoor === row.id ? "bg-gray-300" : "bg-white"
              }`}
            >
              <Box className="flex-grow p-3 flex items-center justify-between">
                <Typography
                  onClick={() => createAccessRequest(row.id)}
                  className="font-medium w-[90%]"
                >
                  {row.name}
                </Typography>
              </Box>
              <ThreePointsMenu id={row.id} type={row.device_owned}/>
            </Box>
          ))}
        </Box>
      </main>
      <GenerateCode
        open={modalOpen}
        handleClose={handleClose}
        guestCode={code}
      />
    </div>
  );
};

export default DoorsComponent;
