import { useState, useEffect } from "react";
import { Box, Typography, IconButton, Stack, Button, Menu, MenuItem, Modal as MuiModal, TextField, Alert, Modal } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import AuthorizeDoor from "../services/DoorShareService";
import SendIcon from '@mui/icons-material/Send';
import useUsersDoors from "../hooks/UsersDoorsHook";

const ITEM_HEIGHT = 48;


const ShareDoor =({ id, open, onClose })=> {
    const authorizeDoor = new AuthorizeDoor();
    const [forSubmit, setForSubmit] = useState({
      door_id: id,
      code: ""
    });
    const [responseMessage, setResponseMessage] = useState("");
    const [responseSeverity, setResponseSeverity] = useState("success");
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setForSubmit({
        ...forSubmit,
        [name]: value
      });
    };
  
    const handleAuthorize = async (e) => {
      e.preventDefault();
      console.log(forSubmit);
      try {
        const response = await authorizeDoor.authorizeDoor(forSubmit.code, forSubmit.door_id);
        console.log(response);
        setResponseMessage(response.message);
        setResponseSeverity("success");
      } catch (error) {
        console.log(error.response ? error.response.data : error.message);
        setResponseMessage(error.response.data.error);
        setResponseSeverity("error");
      }
    };
  
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box onSubmit={handleAuthorize} component="form" sx={{ position: 'absolute', top: '25%', left: '50%', transform: 'translate(-50%, -50%)', width: '350px', backgroundColor: 'white', padding: 2, boxShadow: 24, zIndex: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            <Typography id="modal-title" variant="h6" sx={{ fontWeight: 'bold' }}>Ingresa el código del invitado:</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Stack
            sx={{ width: '100%' }}
            spacing={2}
            noValidate
            autoComplete="off"
          >
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              name="code"
              value={forSubmit.code}
              onChange={handleInputChange}
              fullWidth
            />
          </Stack>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SendIcon />}
            sx={{ marginTop: 2 }}
            type="submit"
          >
            Compartir
          </Button>
          {responseMessage && (
            <Alert severity={responseSeverity} sx={{ marginTop: 2 }}>
              {responseMessage}
            </Alert>
          )}
        </Box>
      </Modal>
    );
  }

  const ThreePointsMenu = ({ id , type })=> {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
  
  
    return (
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          {type == true  && 
          <MenuItem>
            Compartir
          </MenuItem>
      }
          <MenuItem onClick={handleClose}>
            Añadir a favoritos
          </MenuItem>
        </Menu>
      </div>
    );
  }



const DoorsUsersComponent = ({}) => {
    const { users, loading, error } = useUsersDoors();
    const [modalOpen, setModalOpen] = useState(false);

    const handleAddClick = () => {
      setModalOpen(true);
    };
  
    const handleClose = () => {
      setModalOpen(false);
    };

    return(
        <div className="flex flex-col">
        <main className="flex-1 overflow-y-auto z">
          <Box className="flex items-center justify-between">
            <Typography variant="h5" className="font-bold mb-4">
              Tus puertas
            </Typography>
            <IconButton onClick={handleAddClick}>
              <AddIcon />
            </IconButton>
          </Box>
          <Box>
            {loading && (
              <Typography className="text-center">Cargando...</Typography>
            )}
            {!loading && users.length === 0 && (
              <Typography className="text-center">
                No hay puertas disponibles
              </Typography>
            )}
            {users.map((row, index) => (
              <Box
                key={index}
              >
                <Box className="flex-grow p-3 flex items-center justify-between">
                  <Typography
                    className="font-medium w-[90%]"
                  >
                    {row.name}
                  </Typography>
                </Box>
                <ThreePointsMenu id={row.id} type={row.device_owned}/>
              </Box>
            ))}
          </Box>
        </main>
        <ShareDoor open={modalOpen} onClose={handleClose} />
      </div>)
}

export default DoorsUsersComponent;