import axios from "../axiosConfig";

const API_URL = process.env.REACT_APP_ACCESS_CORE_URL;

const FaceRecognitionService = {
  registerFaceData: async (descriptors) => {
    const response = await axios.post(`${API_URL}/api/faceRecognition/registerFaceData`, {
      descriptors: descriptors,
    });
    return response.data;
  },

  getFaceData: async () => {
    const response = await axios.get(`${API_URL}/api/faceRecognition/getFaceData`);
    return response.data;
  },

  removeFaceData: async () => {
    const response = await axios.delete(`${API_URL}/api/faceRecognition/removeFaceData`);
    return response.data;
  },
};

export default FaceRecognitionService;