import { useState, useEffect } from "react";
import DoorService from "../services/DoorService";
import DoorShareService from "../services/DoorShareService";


const useDoors = () => {
    const doorService = new DoorService();
    const doorShareService = new DoorShareService();
    const [doors, setDoors] = useState([]);
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState(null);
    const [processingDoor, setProcessingDoor] = useState(null);
    const [loadingDoors, setLoadingDoors] = useState(true);
    const [error, setError] = useState(null);
    const [code, setCode] = useState("");

  const showAlertMessage = (severity, message) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1000);
  };

    const createAccessRequest = async (doorId) => {
        setProcessingDoor(doorId);
        try {
          const response = await doorService.createAccessRequest(doorId);
          showAlertMessage("success", "Abierto correctamente");
          console.log(response.data);
        } catch (error) {
          setError(error);
          setLoadingDoors(false);
          setAlertMessage("Error al abrir la puerta");
          console.log(error);
        } finally {
          setProcessingDoor(null);
        }
      };

      const getDoors = async () => {
        setLoadingDoors(true);
        try {
          const response = await doorService.getDoors();
          setDoors(response.data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingDoors(false);
        }
      };
      const generateCode = async () => {
        setCode("");
        try {
          const response = await doorShareService.generateCode();
          setCode(response.code);
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      };
    
    
      useEffect(() => {
        getDoors();
      }, []);
    
      return {createAccessRequest, doors, loadingDoors, error, generateCode, code };
}

export default useDoors;