import { useState, useEffect, useContext } from "react";
import DeviceService from "../services/DeviceService";
import { AppContext } from "../application/provider";

const useDeviceDoors = () => {
  const deviceService = new DeviceService();
  const [device, setDevice] = useContext(AppContext);
  const [doors, setDoors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getDoors = async () => {
    try {
      const response = await deviceService.getDeviceDoors(device.id);
      setDoors(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDoors();
  }, []);

  return { doors, loading, error };
};

export default useDeviceDoors;
