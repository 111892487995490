

import {useEffect} from 'react';

const clientId = process.env.REACT_APP_CLIENT_ID;
const redirectUri = process.env.REACT_APP_URL+'/callback';
const accessCoreUrl = process.env.REACT_APP_ACCESS_CORE_URL;

const LoginComponent = () => {
    const authorizeUrl = accessCoreUrl+`/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;

    const handleLogin = () => {
        window.location.href = authorizeUrl;
    };
    useEffect(() => {
      window.location.href = authorizeUrl;
    }, []);
    

    return (null);
};

export default LoginComponent;