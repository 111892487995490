import { useEffect, useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import * as faceapi from "face-api.js";
import FaceRecognitionService from "../../services/FaceRecognitionService";

const FaceIndexComponent = () => {
  const videoRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [message, setMessage] = useState(
    "Por favor, mueve tu cara para capturar diferentes ángulos."
  );
  const [faces, setFaces] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const loadModels = async () => {
      try {
        setLoading(true);
        setLoadingMessage("Cargando modelos, por favor espera...");
        await Promise.all([
          faceapi.nets.ssdMobilenetv1.loadFromUri("/models"),
          faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
          faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
        ]);
        setLoading(false);
        startVideo();
      } catch (error) {
        console.error("Error al cargar los modelos:", error);
        setLoadingMessage(
          "Error al cargar los modelos. Por favor, recarga la página."
        );
      }
    };

    loadModels();
  }, []);

  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: {} })
      .then((stream) => {
        videoRef.current.srcObject = stream;
      })
      .catch((err) => console.error("Error al iniciar el video:", err));
  };

  const handleCapture = async () => {
    let descriptors = [];
    setProgress(0);

    while (descriptors.length < 10) {
      const detections = await faceapi
        .detectAllFaces(videoRef.current)
        .withFaceLandmarks()
        .withFaceDescriptors();
      if (detections.length === 1) {
        const detection = detections[0].detection;
        const descriptor = detections[0].descriptor;
        if (detection._score > 0.93) {
          if (
            descriptors.length === 0 ||
            faceapi.euclideanDistance(
              descriptor,
              descriptors[descriptors.length - 1]
            ) > 0.4
          ) {
            descriptors.push(Array.from(descriptor));
            setProgress(descriptors.length);
            setMessage("Buen trabajo! Sigue moviendo tu cara.");
          } else {
            setMessage(
              "Por favor, mueve tu cara para capturar diferentes ángulos."
            );
          }
        } else {
          setMessage("La veracidad de la cara es menor a 0.93");
        }
      } else {
        setMessage("Debe haber exactamente una cara en la cámara.");
      }
    }
    setLoading(true);
    setLoadingMessage("Registrando datos faciales, por favor espera...");
    try {
      const response = await FaceRecognitionService.registerFaceData(
        descriptors
      );
      setMessage(response.message);
      console.log(response);
    } catch (error) {
      console.error("Error al registrar los datos faciales:", error);
      setMessage("Error al registrar los datos faciales.");
    }
    setLoading(false);
  };

  const handleRemoveFace = async () => {
    setLoading(true);
    setLoadingMessage("Eliminando datos faciales, por favor espera...");
    try {
      const response = await FaceRecognitionService.removeFaceData();
      console.log(response);
      setMessage(response.message);
    } catch (error) {
      console.error("Error al eliminar los datos faciales:", error);
      setMessage("Error al eliminar los datos faciales.");
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="flex flex-col">
      <main className="flex-1 overflow-y-auto">
        <Box className="flex items-center justify-between">
          <Typography variant="h5" className="font-bold mb-4">
            Reconocimiento Facial
          </Typography>
          {/* menu de 3 puntos con opcion para eliminar datos faciales */}
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleRemoveFace}>
              Eliminar Datos Faciales
            </MenuItem>
          </Menu>
        </Box>
        <Box p={4}>
          <video
            ref={videoRef}
            style={{ width: "100%", maxHeight: "480px", marginBottom: "1rem" }}
            autoPlay
            muted
          ></video>
          <Box textAlign={"center"}>
            <Typography>Progreso: {progress} / 10</Typography>
            <CircularProgress
              variant="determinate"
              value={(progress / 10) * 100}
            />
            <Typography>{message}</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCapture}
            >
              Capturar
            </Button>
          </Box>

          <Dialog open={loading}>
            <DialogContent>
              <DialogContentText>{loadingMessage}</DialogContentText>
              <CircularProgress />
            </DialogContent>
          </Dialog>
        </Box>
      </main>
    </div>
  );
};

export default FaceIndexComponent;
