import axios from "../axiosConfig";

export default class DoorService {
  urlBase = "";
  token = "";

  constructor() {
    //this.urlBase =  'http://localhost:8000/';
    this.urlBase = process.env.REACT_APP_ACCESS_CORE_URL;
  }

  //curl -X GET http://localhost:8000/api/doors?
  //response [{"id":1,"device_id":1,"relay_number":1,"name":"Torre Nico","enabled":1,"created_at":"2024-10-07T17:39:36.000000Z","updated_at":"2024-10-07T17:39:36.000000Z"}]
  getDoors = (params) => {
    return axios.get(this.urlBase + "/api/doors?" + params);
  };

  //curl -X POST http://localhost:8000/api/createAccessRequest -d '{"door_id":1}'
  createAccessRequest = (door_id) => {
    return axios.post(this.urlBase + "/api/createAccessRequest", {
      door_id: door_id,
    });
  };

  addNewDoor = (relay_number, device_id, name) => {
    if (!relay_number || !device_id || !name) {
      throw new Error("All fields are required: relay_number, device_id, name");
    }
    console.log({ relay_number, device_id, name });
    return axios.post(this.urlBase + "/api/addNewDoor", {
      relay_number: relay_number,
      device_id: device_id,
      name: name,
    });
  };

  setDoorName = (door_id, name) => {
    if (!door_id || !name) {
      throw new Error("All fields are required: door_id, name");
    }
    return axios.post(this.urlBase + "/api/setDoorName", {
      door_id: door_id,
      name: name,
    });
  }
}
