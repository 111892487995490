import { Box, Typography, TextField, Button } from "@mui/material";
import { useState } from "react";
import { AppContext } from "../application/provider";
import { useContext } from "react";
import DeviceService from "../services/DeviceService";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import DoorService from "../services/DoorService";
import { useNavigate } from "react-router-dom";

const AddDoorComponent = () => {
  const deviceService = new DeviceService();
  const doorService = new DoorService();
  const [device, setDevice] = useContext(AppContext);
  const [seats, setSeats] = useState([]);
  const navigate = useNavigate();
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [data, setData] = useState({
    name: "",
    relay_number: "",
    device_id: device.id,
  });

  const handleSeatClick = (id) => {
    setSeats((prevSeats) =>
      prevSeats.map(
        (seat) =>
          seat.relay_number === id && seat.status === "available"
            ? { ...seat, status: "selected" } // Marca el asiento seleccionado
            : {
                ...seat,
                status: seat.status === "selected" ? "available" : seat.status,
              } // Deselecciona el asiento anterior
      )
    );
    // Actualizar el estado del asiento seleccionado
    const seat = seats.find((seat) => seat.relay_number === id);
    setSelectedSeat(seat.status === "available" ? seat : null);
    if (!selectedSeat) {
      setData({
        ...data,
        relay_number: id,
      });
    } else {
      setData({
        ...data,
        relay_number: null,
      });
    }
  };

  const forGo = () => {
    navigate("/deviceDoors");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const getDevicePortMap = async () => {
    try {
      const response = await deviceService.getDevicePortMap(device.id);
      setSeats(response.data);
      console.log(seats);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await setData({
      ...data,
      relay_number: selectedSeat.relay_number,
    });
    await doorService.addNewDoor(data.relay_number, data.device_id, data.name);
  };

  useEffect(() => {
    getDevicePortMap();
  }, []);

  return (
    <Box component="div" className="flex flex-col">
      <Typography variant="h5" className="font-bold">
        Agregar puerta a "{device.name}":
      </Typography>
      <Box onSubmit={handleSubmit} component="form">
        <Box container display="flex" sx={{ marginTop: "16px" }}>
          <TextField
            sx={{ marginTop: "16px",
              width: "100%"
             }}
            required
            label="Nombre de la puerta"
            name="name"
            onChange={handleInputChange}
          />
        </Box>
        <Box
          container
          display="flex"
          justifyContent="column"
          sx={{ marginTop: "16px" }}
        >
          <Typography
            variant="subtitle1"
            className="font-bold text-left mt-4 mb-2"
          >
            Selecciona un puerto disponible:
          </Typography>
        </Box>
        <Box
          container
          sx={{ marginTop: "16px" }}
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          width="100%"
        >
          {seats.map((seat) => (
            <Box key={seat.relay_number} m={1}>
              <Button
                variant="contained"
                color={
                  seat.status === "occupied"
                    ? "secondary"
                    : seat.status === "selected"
                    ? "primary"
                    : "default"
                }
                onClick={() => handleSeatClick(seat.relay_number)}
                disabled={seat.status === "occupied"}
                // el tamaño de los botones tiene que ser relativo al tamaño de la pantalla, que entren 4 botones por fila
                sx={{ width: "100px", height: "100px" }}
              >
                {seat.row}
                {seat.relay_number}
              </Button>
            </Box>
          ))}
        </Box>
        <Box
          container
          display="flex"
          justifyContent="center"
          sx={{ marginTop: "16px" }}
        >
          <Button
            type="submit"
            sx={{ marginTop: "16px" }}
            variant="contained"
            onClick={() => forGo()}
          >
            Añadir Puerta
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddDoorComponent;
