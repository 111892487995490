import { Box, Typography } from "@mui/material";
import DeviceService from "../services/DeviceService";
import { useState, useEffect } from "react";
import { AppContext } from '../application/provider'
import { useContext } from 'react'
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';
import useDevice from "../hooks/DeviceHook";


const ITEM_HEIGHT = 48;

const DeviceMenu = ({row})=> {
  const [anchorEl, setAnchorEl] = useState(null);
  const [device , setDevice] = useContext(AppContext)
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <Link to={`/deviceDoors`}>
        <MenuItem onClick={()=>setDevice(row)}>
          Administrar puertas
        </MenuItem>
        </Link>
      </Menu>
    </div>
  );
}

const DevicesComponent = () => {
  const {devices, loading, error} = useDevice();

  return (
    <div className="flex flex-col">
      <main className="flex-1 overflow-y-auto">
        <Box>
          <Typography variant="h5" className="font-bold mb-4 h-[45px]">
            Tus dispositivos
          </Typography>

          {devices.map((row, index) => (
            <Box
              key={index}
              className="flex items-center mb-3 bg-white rounded-lg shadow"
            >
              <Box className="flex-grow p-3 flex items-center justify-between">
                <Typography className="font-medium">{row.name}</Typography>
              </Box>
              <DeviceMenu row={row} />
            </Box>
          ))}
        </Box>
      </main>
    </div>
  );
};

export default DevicesComponent;
