import "./App.css";
import { Routes, Route } from "react-router-dom";
import CallbackComponent from "./components/CallbackComponent";
import PrivateRoute from "./components/PrivateRoute";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import MyProvider from "./application/provider";
import AccountComponent from "./components/AccountComponent";
import Sidebar from "./components/Sidebar";
import DoorsComponent from "./components/DoorsComponent";
import DevicesComponent from "./components/DevicesComponent";
import DeviceDoorsComponent from "./components/DeviceDoorsComponent";
import { Box } from "@mui/material";
import AddDoorComponent from "./components/AddDoorForm";
import LoginComponent from "./components/LoginComponent";
import FaceIndexComponent from "./components/faceRecognition/FaceIndexComponent";
import DoorsUsersComponent from "./components/DoorUsersComponent";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MyProvider>
        <Box sx={{ display: "flex", position: "relative"}} >
          <Sidebar  />
          <Box id="content" sx={{ width: "100%", padding:1}} >
            <AccountComponent />
            <Box sx={{ padding: 1, marginTop: "40px"}}>
              <Routes>
                <Route
                  path="/"
                  element={<PrivateRoute element={DoorsComponent} />}
                />
                <Route path="/login" element={<LoginComponent />} />
                <Route path="/callback" element={<CallbackComponent />} />
                <Route
                  path="/devices"
                  element={<PrivateRoute element={DevicesComponent} />}
                />
                <Route path="/deviceDoors" element={<DeviceDoorsComponent />} />
                <Route path="/devices/addDoor" element={<AddDoorComponent />} />
                <Route path="/faceRecognitionRegister" element={<FaceIndexComponent/>} />
                <Route path="/users/access" element={<DoorsUsersComponent/>}/>
              </Routes>
            </Box>
          </Box>
        </Box>
      </MyProvider>
    </ThemeProvider>
  );
}

export default App;
