import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Modal,
  Stack,
  TextField
} from "@mui/material";
import { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";
import { AppContext } from "../application/provider";
import { useContext } from "react";
import DeviceDoorsHook from "../hooks/DeviceDoorsHook";

const ITEM_HEIGHT = 48;

const DisableDoors = ({id, open, onClose }) => {
  return(
  <Modal
  open={open}
  onClose={onClose}
  >
  <Box sx={{ position: 'absolute', top: '25%', left: '50%', transform: 'translate(-50%, -50%)', width: '85%', backgroundColor: 'white', padding: 6, boxShadow: 24, zIndex: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%'}}>
        <Typography id="modal-title" variant="h6" sx={{ fontWeight: 'bold' }}>¿Estás seguro que quieres deshabilitar esta puerta?</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '5%'}}>
          <Button>Sí</Button>
          <Button onClick={onClose}>No</Button>
      </Box>

  </Box>
  </Modal>);
}

const ChangeName = ({ id, open, onClose }) => {
  const [forSubmit, setForSubmit] = useState({
    door_id: id,
    name: ""
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setForSubmit({
      ...forSubmit,
      [name]: value
    });
  };

  const handleChangeName = async (e) => {
    e.preventDefault();
    console.log(forSubmit);
   
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box onSubmit={handleChangeName} component="form" sx={{ position: 'absolute', top: '25%', left: '50%', width: '350px',transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: 2, boxShadow: 24, zIndex: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: 2 }}>
          <Typography id="modal-title" variant="h6" sx={{ fontWeight: 'bold' }}>Cambiar nombre:</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Stack
          sx={{ width: '100%' }}
          spacing={2}
          noValidate
          autoComplete="off"
        >
          <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            variant="filled"
            size="small"
            name="name"
            value={forSubmit.name}
            onChange={handleInputChange}
            fullWidth
          />
        </Stack>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
          type="submit"
        >
          OK
        </Button>
      </Box>
    </Modal>
  );
}

const DoorMenu = ({ id }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showShare, setShowShare] = useState(false);
  const [changeName, setChangeName] = useState(false);  
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDisable = () => {
    setShowShare(true);
    setAnchorEl(null);
  };
  const handleCloseDisable = () => {
    setShowShare(false);
  };
  const handleChange = () =>{
    setChangeName(true);
    setAnchorEl(null);
  }
  const handleCloseChange = () => {
    setChangeName(false);
  };


  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem onClick={handleChange}>
          Cambiar Nombre
        </MenuItem>
        <MenuItem onClick={handleDisable}>
          Deshabilitar
        </MenuItem>
      </Menu>
      {showShare && <DisableDoors id={id} open={showShare} onClose={handleCloseDisable}/>}
      {changeName && <ChangeName id={id} open={changeName} onClose={handleCloseChange}/>}
    </div>
  );
}

const DeviceDoorsComponent = () => {
  const [device, setDevice] = useContext(AppContext);
  const { doors, loading, error } = DeviceDoorsHook(device.id);

  // DeviceMenu

  return (
    <div className="flex flex-col">
      <main className="flex-1 overflow-y-auto">
        <Box className="flex items-center justify-between">
          <Typography variant="h5" className="font-bold mb-4">
            Puertas de {device.name}:
          </Typography>
          <Link to="/devices/addDoor">
            <IconButton>
              <AddIcon />
            </IconButton>
          </Link>
        </Box>
        {/* list of doors */}
        {doors && (
          <Box>
            {doors.map((row, index) => (
              <Box
                key={index}
                className="flex items-center mb-3 bg-white rounded-lg shadow"
              >
                <Box className="flex-grow p-3 flex items-center justify-between">
                  <Typography className="font-medium">{row.name}</Typography>
                </Box>
                <DoorMenu id={row.id} setDevice={setDevice} />
              </Box>
            ))}
          </Box>
        )}

        {/* dialog loading */}
        {loading && (
          <Typography className="text-center">Cargando...</Typography>
        )}
        {/* dialog error */}
        {error && (
          <Typography className="text-center">
            Error: {error.message}
          </Typography>
        )}
      </main>
    </div>
  );
};
export default DeviceDoorsComponent;
