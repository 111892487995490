import {createContext , useState} from "react"


const MyProvider = (props) =>{
    const [device , setDevice] = useState({})
    return(
    <div>
        <AppContext.Provider value= {[device, setDevice]}>
            {props.children}
        </AppContext.Provider>
    </div>
)
}
export default MyProvider
export const AppContext = createContext()